var NAVIGATION = {
    State: function(routeFullPath, navigationItem) {
        return routeFullPath.indexOf(navigationItem.Route.Route) !== -1 ? "active" : "inactive";
    }
};

var DESKTOP = [
    {
        "Name": "About Us",
        "Route": {
            "Route": "/AboutUs"
        },
        "Children": [
            {
                "Name": "Who We Are",
                "Route": {
                    "Route": "/AboutUs/WhoWeAre"
                }
            },
            {
                "Name": "What We Do",
                "Route": {
                    "Route": "/AboutUs/WhatWeDo"
                }
            },
            {
                "Name": "Core Values",
                "Route": {
                    "Route": "/AboutUs/CoreValues"
                }
            }
        ],
        "Active": true
    },
    {
        "Name": "Educators",
        "Route": {
            "Route": "/Educators"
        },
        "Children": [],
        "Active": true
    },
    {
        "Name": "Our Focus",
        "Route": {
            "Route": "/OurFocus"
        },
        "Children": [
            {
                "Name": "Education",
                "Route": {
                    "Route": "/OurFocus/Education"
                }
            },
            {
                "Name": "Products & Services",
                "Route": {
                    "Route": "/OurFocus/ProductsAndServices"
                }
            },
            {
                "Name": "Partners",
                "Route": {
                    "Route": "/OurFocus/Partners"
                }
            }
        ],
        "Active": true
    },
    {
        "Name": "Families",
        "Route": {
            "Route": ""
        },
        "Children": [],
        "Active": false
    },
    {
        "Name": "Research",
        "Route": {
            "Route": "/Research"
        },
        "Children": [
            {
                "Name": "What We Follow",
                "Route": {
                    "Route": "/Research/WhatWeFollow"
                }
            },
            {
                "Name": "Hub",
                "Route": {
                    "Route": "/Research/Hub"
                }
            }
        ],
        "Active": true
    },
    {
        "Name": "How to Help",
        "Route": {
            "Route": ""
        },
        "Children": [],
        "Active": false
    },
    {
        "Name": "Progress",
        "Route": {
            "Route": "/Progress"
        },
        "Children": [],
        "Active": true
    },
    {
        "Name": "Contact Us",
        "Route": {
            "Route": "/ContactUs"
        },
        "Children": [],
        "Active": true
    },
    {
        "Name": "Donate",
        "Route": {
            "Route": "/Donate"
        },
        "Children": [],
        "Active": true
    }
];

var MOBILE = [
    {
        "Name": "About Us",
        "Route": {
            "Route": "/AboutUs"
        },
        "Children": [
            {
                "Name": "Overview",
                "Route": {
                    "Route": "/AboutUs"
                }
            },
            {
                "Name": "Who We Are",
                "Route": {
                    "Route": "/AboutUs/WhoWeAre"
                }
            },
            {
                "Name": "What We Do",
                "Route": {
                    "Route": "/AboutUs/WhatWeDo"
                }
            },
            {
                "Name": "Core Values",
                "Route": {
                    "Route": "/AboutUs/CoreValues"
                }
            }
        ],
        "Active": true
    },
    {
        "Name": "Educators",
        "Route": {
            "Route": "/Educators"
        },
        "Children": [],
        "Active": true
    },
    {
        "Name": "Our Focus",
        "Route": {
            "Route": "/OurFocus"
        },
        "Children": [
            {
                "Name": "Overview",
                "Route": {
                    "Route": "/OurFocus"
                }
            },
            {
                "Name": "Education",
                "Route": {
                    "Route": "/OurFocus/Education"
                }
            },
            {
                "Name": "Product & Services",
                "Route": {
                    "Route": "/OurFocus/ProductsAndServices"
                }
            },
            {
                "Name": "Partners",
                "Route": {
                    "Route": "/OurFocus/Partners"
                }
            }
        ],
        "Active": true
    },
    {
        "Name": "Families",
        "Route": {
            "Route": ""
        },
        "Children": [],
        "Active": false
    },
    {
        "Name": "Research",
        "Route": {
            "Route": "/Research"
        },
        "Children": [
            {
                "Name": "Overview",
                "Route": {
                    "Route": "/Research"
                }
            },
            {
                "Name": "What We Follow",
                "Route": {
                    "Route": "/Research/WhatWeFollow"
                }
            },
            {
                "Name": "Hub",
                "Route": {
                    "Route": "/Research/Hub"
                }
            }
        ],
        "Active": true
    },
    {
        "Name": "How to Help",
        "Route": {
            "Route": ""
        },
        "Children": [],
        "Active": false
    },
    {
        "Name": "Progress",
        "Route": {
            "Route": "/Progress"
        },
        "Children": [],
        "Active": true
    },
    {
        "Name": "Contact Us",
        "Route": {
            "Route": "/ContactUs"
        },
        "Children": [],
        "Active": true
    },
    {
        "Name": "Donate",
        "Route": {
            "Route": "/Donate"
        },
        "Children": [],
        "Active": true
    }
];

module.exports = {
    NAVIGATION,
    DESKTOP,
    MOBILE
};