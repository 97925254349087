<template>
    <div id="side-navigation" :style="`width: ${Width()}`" v-if="show">
        <div class="btn header" @click="Toggle(expanded)">
            <div class="flex layout-50">
                <div class="layout">
                    <h2 class="no-margin-b no-pad-b">{{ name }}</h2>
                </div>
                <div class="layout">
                    <img class="right" src="@/assets/Images/PrimaryNavigationMenu.png" alt="Icon - Side Navigation Menu" title="Side Navigation Menu" width="50" height="50"/>
                </div>
            </div>
            <p class="single sub-name" v-html="navigation.subName"></p>
        </div>
        <ul>
            <li class="navigation-item" v-for="(navigationItem, n) in navigation.Children" :key="n">
                <NavigationItem :navigationItem="navigationItem" @navigate="Navigate($event)"/>
            </li>
        </ul>
    </div>
</template>

<script>
import $ from "jquery";
import NavigationItem from "@/components/Navigations/SideNavigation/NavigationItem.vue";
export default {
    name: "SideNavigation",
    props: {
        name: String,
        navigation: Object,
        show: Boolean,
        mobile: Boolean
    },
    components: { NavigationItem },
    data: () => ({ expanded: false }),
    methods: {
        Toggle: function(expanded) {
            this.expanded = !expanded;
            $("#side-navigation").stop().animate({
                height: this.expanded ? "100%" : "70px"
            }, 300);
        },
        Navigate: function(subNavigationItem) {
            this.$router.push(subNavigationItem.Action);
            this.Toggle(true);
            this.$emit(
                "set-side-navigation-sub-name",
                `<b>${subNavigationItem.Focus}</b> > ${subNavigationItem.Name}`
            );
        },
        Width: function() { return !this.mobile ? "30%" : "70%"; }
    },
    emits: ["set-side-navigation-sub-name"]
};
</script> 