<template>
    <BodyIntroduction
        :icon="bodyIntroductionIcon"
        heading="Welcome"
        copy="<b>Future Forward Education</b> is a nonprofit organization that wants to improve the school experience for our students."
    />
    <div class="wide-content">
        <MultiColorHeader
            type="h1"
            heading="We Believe In Youth"
            highlight="Youth"
        />
        <p>Community building and taking a whole-family approach to moving education forward is essential to creating a better experience in education for our students. Part of creating that experience involves starting at system-level, enabling us to discover and develop solutions, so that our youth can exist within a welcoming learning environment, <span class="text-focus">one that gives freedom for exploration in the classroom</span>.</p>
    </div>

    <!-- Mission & Vision -->
    <div id="mission-vision">
        <div id="mission">
            <div class="no-margin-tb wide-content">
                <div class="flex m-block">
                    <div class="m-align-c">
                        <img src="@/assets/Images/Icons/V3/Mission.png" alt="Icon - Mission" title="Mission" width="150" height="150"/>
                    </div>
                    <div>
                        <h1>Mission</h1>
                        <p>Improve the education experience for students and their families through analysis and reform by creating solutions through increased access to resources, building partnerships and monitoring student progress. By maximizing our whole-family approach, we enable our youth to harness their growth potential for an ever-changing tomorrow.</p>
                    </div>
                </div>
            </div>
        </div>
        <div id="vision">
            <div class="no-margin-tb wide-content">
                <div class="flex m-block">
                    <div class="m-align-c">
                        <img src="@/assets/Images/Icons/V3/Vision.png" alt="Icon - Vision" title="Vision" width="150" height="150"/>
                    </div>
                    <div>
                        <h1>Vision</h1>
                        <p>That all youth can experience a quality education, empowering them to harness their full potential and become the next leaders of world change.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <DonationPreviewPrompt message="A <span>future</span> worth walking into"/>
    <div class="wide-content">
        <p>We break down our focus and strategies into three main categories that we feel encompass the work we are doing.</p>
    </div>
    <FocusCategories/>
    <div class="wide-content">
        <p>Today, we are engaging with schools to determine their needs and wants and taking action to generate and seek out resources for the classroom, implement programming and donating stuffed backpacks for newcomers. We will continue to create more efforts for these schools while establishing new relationships. View the <a href="https://believeinyou.org/FFE//Documents/School Connectivity Plan.pdf?v=2" target="_blank">School Connectivity Plan</a>.</p>
        <DonateCallout/>
    </div>
    <StrategicPlanSourceReferenceBanner/>
    <img class="span" src="@/assets/Images/StudentsWorking.jpg" alt="Students Working" title="Students Working"/>
</template>

<script>
import BodyIntroduction from "@/components/BodyIntroduction";
import FocusCategories from "@/components/FocusCategories";
import MultiColorHeader from "@/components/MultiColorHeader.vue";
import DonateCallout from "@/components/DonateCallout.vue";
import StrategicPlanSourceReferenceBanner from "@/components/Banners/SourceReference/StrategicPlan.vue";
import DonationPreviewPrompt from "@/components/DonationPreviewPrompt.vue";
export default {
    name: "MainView",
    components: {
        BodyIntroduction,
        FocusCategories,
        MultiColorHeader,
        DonateCallout,
        StrategicPlanSourceReferenceBanner,
        DonationPreviewPrompt
    },
    data: () => ({ bodyIntroductionIcon: {} }),
    beforeMount() {
        this.bodyIntroductionIcon = {
            name: "Welcome",
            source: "Welcome.png",
            width: 140,
            height: 140
        };
    }
};
</script>