<template>
    <div id="header">
        <div id="top">
            <div id="brand" class="m-align-c narrow-content">
                <a href="/">
                    <img src="@/assets/Images/Logo.png" :alt="projectName" :title="projectName" width="320"/>
                </a>
            </div>
            <div id="navigation">
                <div class="row">
                    <div class="p-desktop">
                        <Navigation :navigation="navigation.desktop" :showDropdowns="true"/>
                    </div>
                    <div class="p-mobile">
                        <Navigation
                            :navigation="navigation.mobile"
                            :showDropdowns="true"
                            :mobile="true"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div id="feature-media">
            <div class="p-desktop">
                <Carousel :carousel="CarouselData.Main" :show="!ASSETS.ShowCarousel($route, CONFIG)"/>
            </div>
            <div class="p-mobile">
                <img class="span" src="@/assets/Images/FeatureMain.jpg" alt="Feature" title="Feature"/>
            </div>
        </div>
    </div>
    <div id="platform">
        <span class="p-desktop"></span>
        <span class="p-mobile"></span>
    </div>
    <SideNavigation
        name="Our Focus"
        :navigation="sideNavigation"
        :show="$route.fullPath.indexOf('/OurFocus') !== -1"
        :mobile="mobile"
        @set-side-navigation-sub-name="SetSideNavigationSubName($event)"
    />
</template>

<script>
import Navigation from "@/components/Template/Navigation";
import Carousel from "@/components/Carousel";
import CarouselData from "@/assets/JS/Carousel.json";
import SideNavigation from "@/components/Navigations/SideNavigation/";
import { MASTER } from "@/assets/JS/Master.js";
import { ASSETS } from "@/assets/JS/Assets.js";
import { CONFIG } from "@/assets/JS/Config.js";
import EducationData from "@/assets/JS/Cards/OurFocus/Education.json";
import ProductsAndServicesData from "@/assets/JS/Cards/OurFocus/ProductsAndServices.json";
import PartnersData from "@/assets/JS/Cards/OurFocus/Partners.json";
import $ from "jquery";
export default {
    name: "Header",
    props: {
        projectName: String,
        navigation: Object
    },
    components: {
        Navigation,
        Carousel,
        SideNavigation
    },
    data: () => ({
        CarouselData,
        sideNavigation: { subName: "" },
        ourFocusData: {
            "Education": EducationData,
            "Products & Services": ProductsAndServicesData,
            "Partners": PartnersData
        },
        ASSETS,
        CONFIG,
        MASTER,
        mobile: false
    }),
    methods: {
        SetSideNavigationSubName: function(name) { this.sideNavigation.subName = name; },

        // Adds details to children items of Focus Data
        SetFocusData: function() {
            for (const focus in this.ourFocusData) {
                const children = this.ourFocusData[focus];
                for (const child of children) {
                    child.Focus = focus;
                }
            }
        }

    },
    mounted() {
        ASSETS.Construct($, MASTER);
        this.mobile = MASTER.Mobile();

        // Set side navigation
        this.SetFocusData();
        for (const navigationItem of this.navigation.desktop) {
            if (navigationItem.Name === "Our Focus") {
                for (const navigationChild in navigationItem.Children) {
                    const child = navigationItem.Children[navigationChild];
                    child.Children = this.ourFocusData[child.Name];
                }
                this.sideNavigation = { ...navigationItem };
            }
        }

    }
};
</script>